import axios from "axios";

export default class GraphHelper {
    static endpoints = {
        BSC: "https://api.thegraph.com/subgraphs/name/elkfinance/elkdex-bsc",
        AVAX: "https://api.thegraph.com/subgraphs/name/elkfinance/elkdex-avax",
        HECO: "https://api.thegraph.com/subgraphs/name/elkfinance/elkdex-heco",
        MATIC: "https://api.thegraph.com/subgraphs/name/elkfinance/elkdex-matic",
        FTM: "https://api.thegraph.com/subgraphs/name/elkfinance/elkdex-ftm",
        XDAI: "https://api.thegraph.com/subgraphs/name/elkfinance/elkdex-xdai",
        HARMONY: "https://api.thegraph.com/subgraphs/name/elkfinance/elkdex-harmony",
        KCC: "https://kcc-graph.elk.finance/subgraphs/name/elkfinance/elkdex-kcc",
        OKEX: "https://okex-graph.elk.finance/subgraphs/name/elkfinance/elkdex-okex",
        HOO: "https://hoo-graph.elk.finance/subgraphs/name/elkfinance/elkdex-hoo",
        ELASTOS: "https://elastos-graph.elk.finance/subgraphs/name/elkfinance/elkdex-elastos",
        MOONRIVER: "https://api.thegraph.com/subgraphs/name/elkfinance/elkdex-moonriver",
        CRONOS: "https://cronos-graph.elk.finance/subgraphs/name/elkfinance/elkdex-cronos",
        TELOS: "https://telos-graph.elk.finance/subgraphs/name/elkfinance/elkdex-telos",
        FUSE: "https://api.thegraph.com/subgraphs/name/elkfinance/elkdex-fuse",
        IOTEX: "https://iotex-graph.elk.finance/subgraphs/name/elkfinance/elkdex-iotex",
        ETHEREUM: "https://api.thegraph.com/subgraphs/name/elkfinance/elkdex-eth",
        ARBITRUM: "https://api.thegraph.com/subgraphs/name/elkfinance/elkdex-arbitrum",
        OPTIMISM: "https://api.thegraph.com/subgraphs/name/elkfinance/elkdex-optimism",
    }

    static async getPrice(chain = "AVAX") {
        try {
            let endpoint = this.endpoints[chain];
            return axios({
                url: endpoint,
                method: "post",
                data: {
                    query: `
                    query {
                        tokens(first: 1, where: {id: "0xeeeeeb57642040be42185f49c52f7e9b38f8eeee"}) {
                            id,
                            symbol,
                            name,
                            decimals,
                            tokenDayData(orderBy:date, orderDirection:desc, first:1){
                                date,
                                priceUSD
                            }
                        }
                    }`
                }
            }, {headers: {'Content-Type': 'application/json'}});
        } catch (e) {
            console.log(e)
        }
    }

    static async getUsers(chain = "AVAX") {
        let endpoint = this.endpoints[chain];
        return axios({
            url: endpoint,
            method: "post",
            data: {
                query: `
                    query {
                        users(first:1000){
                            id
                        }
                    }
                `
            }
        }, {headers: {'Content-Type': 'application/json'}}).catch(e => console.log(e));
    }

    static async getDayVolume(chain = "AVAX") {
        let endpoint = this.endpoints[chain];
        return axios({
            url: endpoint,
            method: "post",
            data: {
                query: `
                    query {
                      elkDayDatas (orderBy:date, orderDirection:desc){
                        date,
                        dailyVolumeUSD
                      }
                    }
                `
            }
        }, {headers: {'Content-Type': 'application/json'}}).then(r => r);
    }
}
