import {Chains} from "../consts/Chains.js";
import GraphHelper from "./GraphHelper";
import APIHelper from "./APIHelper";

export default class ReportHelper {
    static getBlockchainCount = () => {
        return Object.keys(Chains).length
    }
    static getUniqueUserCount = async () => {
        // get data
        let data = await APIHelper.getElkNet()

        const [datav1, datav2] = await Promise.all([data.v1, data.v2])
        return parseInt(datav1["data"]["total"].users) + parseInt(datav2["data"]["v2-total"].users)
    }
    static getPrice = async (chain = Chains.AVAX) => {
        return (GraphHelper.getPrice(chain)).catch(r => 0).then(r => {
            try {
                return parseFloat(r.data.data.tokens[0].tokenDayData[0].priceUSD)
            } catch (e) {
                return 0
            }
        })
    }
    static getElkNetTransferred = () => {
        return {in: 1, out: 2}
    }
    static getElkNetTransferredUSD = () => {
        return {in: 1, out: 2}
    }
}

