import elkFinanceLogo from "../images/elk-finance-logo.svg";
import seperator from "../images/seperator.png";
import dashboard from "../images/dashboard.png";
import React from "react";
import {ChainToggler} from "./ChainToggler";

export function Navbar({currentChain, setCurrentChain, toggleChain, currentChainLogo}) {
    return <nav className="navbar BG-SECONDARY mt-5 mt-sm-0">
        <div className="container ">
            <div className="col-md-6">
                <a href="#" className="navbar-brand">
                    <img height={64} className={"mx-2"}
                         src={elkFinanceLogo}
                         alt={"Elk Finance logo"}/>
                    <img src={seperator} alt="green seperator" className={"mx-2"}/>
                    <img src={dashboard} alt="Dashboard green text" className={"mx-2"}/>
                </a>
            </div>
            <div className="col-offset-md-2 col-offset-lg-3"></div>
            <div className="order-first order-md-last col-lg-3 col-md-4 col-sm-12 col-12 mb-sm-4 mb-4 mb-md-0">
                <ChainToggler className={"order-first order-sm-last"} currentChain={currentChain}
                              setCurrentChain={setCurrentChain}
                              toggleChain={toggleChain} currentChainLogo={currentChainLogo}/>
            </div>
        </div>
    </nav>;
}