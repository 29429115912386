import {
    ArgumentAxis,
    Chart,
    CommonSeriesSettings,
    Crosshair,
    Grid, Legend,
    Margin,
    Series, Tooltip,
    ValueAxis
} from "devextreme-react/chart";
import Skeleton from "react-loading-skeleton";
import React from "react";

export function ChartFeesPerDay(props) {
    let dayData = props.dayData
    let onlyUnique = props.onlyUnique
    if (dayData.length > 0) {
        return <Chart
            palette="Violet"
            dataSource={dayData}
            className={"graph p-2 mb-3"}
            animation={false}
        >
            <CommonSeriesSettings
                argumentField="date"
                name="date"
            />
            <Margin bottom={20}/>
            {dayData.map(element => {
                return <Series key={"date"} valueField={"dailyVolumeUSD"} type={"spline"}
                               color={"#039652"} showInLegend={false}/>;
            })}
            <ArgumentAxis
                valueMarginsEnabled={false}
                discreteAxisDivisionMode="crossLabels"

            >
            </ArgumentAxis>
            <ValueAxis>
                <Grid visible={false}/>
            </ValueAxis>
            <Crosshair
                enabled={true}
                color="#949494"
                width={1}
                dashStyle="dot"
            />
            <Legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
                itemTextPosition="bottom"
            />
            <Tooltip enabled={true} customizeTooltip={(arg) => {
                return {
                    text: `<sub>${arg.argumentText.slice(0, 15)}</sub> <br><strong>${arg.value}</strong><br/>`
                }
            }}/>
        </Chart>
    } else {
        return <Skeleton enableAnimation={true} width={420}/>
    }
}