import React from "react";
import {Navbar} from "./Navbar";

export function Header({currentChain, setCurrentChain, toggleChain, currentChainLogo}) {
    return <header className={"mb-1 mt-2"}>
        <Navbar currentChain={currentChain} setCurrentChain={setCurrentChain}
                toggleChain={toggleChain} currentChainLogo={currentChainLogo}/>
        <div className="container mb-0">
            <div className="row">
                <div className="col-md-1"/>
                <div className="col-md-10">
                </div>
                <div className="col-md-1"/>
            </div>
        </div>
    </header>
}