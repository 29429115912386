import avaxLogo from "../images/avax.svg";
import bscLogo from "../images/bsc.svg";
import ftmLogo from "../images/ftm.svg";
import maticLogo from "../images/matic.svg";
import hecoLogo from "../images/heco.svg";
import xdaiLogo from "../images/xdai.svg";
import movrLogo from "../images/movr.jpg";
import elaLogo from "../images/ela.png";
import oktLogo from "../images/okt.png";
import oneLogo from "../images/one.png";
import kccLogo from "../images/kcc.png";
import hooLogo from "../images/hoo.png";
import cronosLogo from "../images/cronos.png";
import telosLogo from "../images/telos.png";
import fuseLogo from "../images/fuse.png";
import iotexLogo from "../images/iotex.png";
import ethLogo from "../images/ethereum.svg";
import arbitrumLogo from "../images/arbitrum.svg";
import optimismLogo from "../images/optimism.svg";
import kavaLogo from "../images/kava.svg";
import bitTorrentLogo from "../images/bitTorrent.svg";

export const Chains = {
    "AVAX": "AVAX",
    "ARBITRUM":"ARBITRUM",
    "BSC": "BSC",
    "BITTORRENT":"BITTORRENT",
    "CRONOS": "CRONOS",
    "ETHEREUM":"ETHEREUM",
    "ELASTOS": "ELASTOS",
    "FTM": "FTM",
    "FUSE": "FUSE",
    "XDAI": "XDAI",
    "HARMONY": "HARMONY",
    "HOO": "HOO",
    "HECO": "HECO",
    "IOTEX": "IOTEX",
    "KAVA":"KAVA",
    "KCC": "KCC",
    "MOONRIVER": "MOONRIVER",
    "OKEX": "OKEX",
    "OPTIMISM":"OPTIMISM",
    "MATIC": "MATIC",
    "TELOS": "TELOS"
}


export const ChainLogos = {
    "AVAX": avaxLogo,
    "BSC": bscLogo,
    "FTM": ftmLogo,
    "MATIC": maticLogo,
    "HECO": hecoLogo,
    "XDAI": xdaiLogo,
    "MOONRIVER": movrLogo,
    "ELASTOS": elaLogo,
    "OKEX": oktLogo,
    "KCC": kccLogo,
    "HARMONY": oneLogo,
    "HOO": hooLogo,
    "TELOS": telosLogo,
    "CRONOS": cronosLogo,
    "FUSE": fuseLogo,
    "IOTEX": iotexLogo,
    "ETHEREUM": ethLogo,
    "ARBITRUM": arbitrumLogo,
    "OPTIMISM": optimismLogo,
    "KAVA": kavaLogo,
    "BITTORRENT": bitTorrentLogo
}