import "@fortawesome/fontawesome-free/css/all.css?123";
import "@popperjs/core"
import "bootswatch/dist/darkly/bootstrap.min.css";
import "d3-format";
import 'devextreme/dist/css/dx.light.css';
import "devextreme/dist/js/dx.all";
import "./styles/App.css";
import _ from "lodash";
import {Helmet} from "react-helmet";
import {Chains, ChainLogos} from "./consts/Chains";
import React, {useEffect, useState} from 'react';
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import {Header} from "./components/Header";
import {getCurrentChainPretty} from "./helpers/ChainHelper";

import ReportHelper from "./helpers/ReportHelper";
import APIHelper from "./helpers/APIHelper";
import Web3Helper from "./helpers/Web3Helper";

import GraphHelper from "./helpers/GraphHelper";
import * as d3 from "d3-format";
import {ChartFeesPerDay} from "./components/charts/FeesPerDay"
import {ChartTransfers} from "./components/charts/Transfers";


function TotalValueMovedCrossChain({elknetTransferred, averagePrice}) {
    return <SkeletonTheme baseColor={"#192225"} highlightColor={"#444"}>
        <div className="row mb-3">
            <div className="col-xl-12 ps-2 pe-sm-0">
                <div className="card bg-white rounded-0 ">
                    <div className="card-body text-dark">
                        <span>TOTAL VALUE MOVED CROSS-CHAIN</span>
                        <h6 className={"mb-0 d-flex flew-row justify-content-between fw-bolder"}>
                            <small>USD</small> {elknetTransferred && averagePrice ? (d3.format(".5s")(elknetTransferred * averagePrice)) :
                            <Skeleton enableAnimation width={100}/>}</h6>
                    </div>
                </div>
            </div>
        </div>
    </SkeletonTheme>;
}

function DashboardMetrics({
                              uniqueUsers,
                              averagePrice,
                              totalTransfers,
                              totalMarketcap,
                              currentChain,
                              currentChainPrice,
                              currentChainCirculatingSupply,
                              stakingRewards,
                              currentChainTransfersToday,
                              currentChainMarketCap,
                              elknetTransfers,
                              elknetTransferred,
                              elknetUsers
                          }) {
    return <div className="row mb-1">
        <div className="col-lg-10">
            <div className="row no-gutters">
                <div className="col-md-6" id={"global"}>
                    <div className="row">
                        <div className="col-md-12"><h5 className={"ms-1 my-3 fw-light"}><small
                            className={"text-monospace"}>GLOBAL</small></h5></div>
                    </div>
                    <div className="row">
                        <div className="col-6 mb-2 pe-1 ps-2 ">
                            <div className="card h-100">
                                <div className="card-body bg-purple">
                                    <small className={"mb-3 font-weight-light d-block"}>BLOCKCHAINS</small>
                                    <h6 className={"mb-0 d-flex flew-row justify-content-between fw-bolder"}>
                                        <small></small>{ReportHelper.getBlockchainCount() ||
                                    <Skeleton width={100} enableAnimation/>}</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 mb-2 pe-sm-0 ps-1">
                            <div className="card h-100">
                                <div className="card-body bg-purple">
                                    <small className={"mb-3 font-weight-light d-block"}>UNIQUE USERS</small>
                                    <h6 className={"mb-0 d-flex flew-row justify-content-between fw-bolder"}>
                                        <span></span>{uniqueUsers ? d3.format(".3s")(uniqueUsers) :
                                        <Skeleton width={100} enableAnimation/>}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6  mb-2 pe-1 ps-2">
                            <div className="card h-100">
                                <div className="card-body bg-purple">
                                    <small className={"mb-3 font-weight-light d-block"}>PRICE AVERAGE</small>
                                    <h6 className={"mb-0 d-flex flew-row justify-content-between fw-bolder"}>
                                        <small>USD</small> {averagePrice ? d3.format(".3f")(averagePrice) :
                                        <Skeleton width={100} enableAnimation/>}</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 mb-2 pe-sm-0 ps-1">
                            <div className="card h-100">
                                <div className="card-body bg-purple">
                                    <small className={"mb-3 font-weight-light d-block text-truncate"}>TOTAL
                                        TRANSFERS
                                        DONE</small>
                                    <h6 className={"mb-0 d-flex flew-row justify-content-between fw-bolder"}>
                                        <span></span><span
                                        className={"text-truncate"}>{totalTransfers["num-transfers"] ? d3.format(".3s")(totalTransfers["num-transfers"]) :
                                        <Skeleton width={100} enableAnimation={true}/>}</span>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 mb-2 pe-1 ps-2">
                            <div className="card h-100">
                                <div className="card-body bg-purple">
                                    <small className={"mb-3 font-weight-light d-block"}>TOTAL MARKETCAP</small>
                                    <h6 className="align-self-end mb-0 d-flex flew-row justify-content-between fw-bolder">
                                        <small>USD</small> <span
                                        className={"text-truncate"}>{totalMarketcap ? d3.format(".3s")(totalMarketcap) :
                                        <Skeleton width={100} enableAnimation={true}/>} </span></h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 mb-2 pe-sm-0 ps-1">
                            <div className="card h-100">
                                <div className="card-body bg-purple">
                                    <small className={"mb-3 font-weight-light d-block text-truncate"}>ELK
                                        TRANSFERRED
                                        TOTAL</small>
                                    <h6 className={"mb-0 d-flex flew-row justify-content-between fw-bolder"}>
                                        <small>ELK</small> <span
                                        className={"text-truncate"}>{totalTransfers["amount-transfers"] ? d3.format(".3s")(totalTransfers["amount-transfers"]) :
                                        <Skeleton width={100} enableAnimation={true}/>}</span>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6" id={"current"}>
                    <div className="row">
                        <div className="col-md-12"><h5 className={"ms-1 my-3 fw-light"}><small
                            className={"text-monospace"}>{currentChain ? currentChain :
                            <Skeleton width={100} enableAnimation={true}/>}</small></h5></div>
                    </div>
                    <div className="row">
                        <div className="col-6  pe-1 ps-2 mb-2">
                            <div className="card h-100">
                                <div className="card-body bg-dark-green">
                                    <small className={"mb-3 font-weight-light d-block"}>PRICE
                                        ({currentChain})</small>
                                    <h6 className={"mb-0 d-flex flew-row justify-content-between fw-bolder"}>
                                        <small>USD</small> {currentChainPrice ? d3.format(".3f")(currentChainPrice) :
                                        <Skeleton width={100} enableAnimation={true}/>}
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 mb-2 pe-sm-0 ps-1">
                            <div className="card h-100">
                                <div className="card-body bg-dark-green">
                                    <small className={"mb-3 font-weight-light d-block text-truncate"}>CIRCULATING
                                        SUPPLY</small>
                                    <h6 className={"mb-0 d-flex flew-row justify-content-between fw-bolder"}>
                                        <small>ELK</small> {currentChainCirculatingSupply ? d3.format(".3s")(currentChainCirculatingSupply) :
                                        <Skeleton width={100} enableAnimation={true}/>}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 px pe-1 ps-2 mb-2">
                            <div className="card h-100" id={"globalTotalTransfers"}>
                                <div className="card-body bg-dark-green">
                                    <small className={"mb-2 font-weight-light d-block"}>STAKING REWARDS</small>
                                    <h6 className={"mb-0 d-flex flew-row justify-content-between fw-bolder"}>
                                        <small></small> {stakingRewards ? d3.format(".3s")(stakingRewards) :
                                        <Skeleton width={100} enableAnimation={true}/>}
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 px mb-2 pe-sm-0 ps-1">
                            <div className="card h-100" id={"globalTotalTransfers"}>
                                <div className="card-body bg-dark-green pb-1">
                                    <small className={"mb-2 font-weight-light d-block text-truncate"}>TRANSFERS
                                        (LAST
                                        24H)</small>
                                    <h6 className={"mb-0 d-flex flew-row justify-content-between fw-bolder"}>
                                        <small>In</small> {d3.format(".3s")(currentChainTransfersToday["num-transfers-in"]) ? currentChainTransfersToday["num-transfers-in"] :
                                        <Skeleton width={100} enableAnimation={true}/>}
                                    </h6>
                                    <h6 className={"mb-0 d-flex flew-row justify-content-between fw-bolder"}>
                                        <small>Out</small> {d3.format(".3s")(currentChainTransfersToday["num-transfers-out"]) ? currentChainTransfersToday["num-transfers-out"] :
                                        <Skeleton width={100} enableAnimation={true}/>}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mb-2 pe-sm-0 ps-2">
                            <div className="card h-100 mb-2">
                                <div className="card-body bg-dark-green pb-1">
                                    <small className={"mb-3 font-weight-light d-block"}>MARKETCAP
                                        ({currentChain})</small>
                                    <h6 className={"mb-0 d-flex flew-row justify-content-between fw-bolder"}>
                                        <small>USD</small> {currentChainMarketCap ? d3.format(".3s")(currentChainMarketCap) :
                                        <Skeleton width={100} enableAnimation={true}/>}
                                    </h6>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div className="col-lg-2">
            <div className="row">
                <div className="col-md-12">
                    <h5 className={"ms-1 my-3 fw-light"}><small
                        className={"text-monospace"}>ELKNET</small></h5></div>
            </div>
            <div className="row no-gutters">
                <div className="col-md-12 d-flex flex-column mb-2 ps-2 pe-sm-0">
                    <div className="card rounded-0 bg-green h-100">
                        <div className="card-body">
                            <small className={"mb-3 font-weight-light d-block text-truncate"}>
                                TOTAL TRANSFERS
                            </small>
                            <h6 className={"mb-0 d-flex flew-row justify-content-between fw-bolder"}>
                                <span></span> <span>{elknetTransfers ? d3.format(".3s")(elknetTransfers) :
                                <Skeleton width={100} enableAnimation={true}/>}</span>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 mb-2 ps-2 pe-sm-0">
                    <div className="card rounded-0 bg-green h-100">
                        <div className="card-body ">
                            <small className={"mb-3 font-weight-light d-block text-truncate"}>
                                TOTAL TRANSFERRED
                            </small>
                            <h6 className={"mb-0 d-flex flew-row justify-content-between fw-bolder"}>
                                <small className={"d-md-block"}>ELK</small> <span
                                className={"text-truncate"}>{elknetTransferred ? d3.format(".3s")(elknetTransferred) :
                                <Skeleton width={100} enableAnimation={true}/>}</span></h6>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className="row">*/}
            {/*    <div className="col-md-12 mb-2 ps-2 pe-sm-0">*/}
            {/*        <div className="card rounded-0 bg-green h-100">*/}
            {/*            <div className="card-body ">*/}
            {/*                <small className={"mb-3 font-weight-light d-block"}>TOTAL USERS</small>*/}
            {/*                <h6 className={"mb-0 d-flex flew-row justify-content-between fw-bolder"}>*/}
            {/*                    <span></span> <span>{elknetUsers ? d3.format(".3s")(elknetUsers) :*/}
            {/*                    <Skeleton width={100} enableAnimation={true}/>}</span></h6>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    </div>
}

function App() {

    const TRANSFER_TEMPLATE = {
        "amount-transfers-in": 0,
        "amount-transfers-out": 0,
        "num-transfers-in": 0,
        "num-transfers-out": 0
    }
    const TOTAL_TRANSFER_TEMPLATE = {
        "amount-transfers": 0,
        "num-transfers": 0
    }

    // prices
    const [avaxPrice, setAvaxPrice] = useState(0)
    const [bscPrice, setBscPrice] = useState(0)
    const [hecoPrice, setHecoPrice] = useState(0)
    const [ftmPrice, setFtmPrice] = useState(0)
    const [maticPrice, setMaticPrice] = useState(0)
    const [onePrice, setOnePrice] = useState(0)
    const [moonriverPrice, setMoonriverPrice] = useState(0)
    const [elastosPrice, setElastosPrice] = useState(0)
    const [hooPrice, setHooPrice] = useState(0)
    const [kccPrice, setKccPrice] = useState(0)
    const [oktPrice, setOktPrice] = useState(0)
    const [xdaiPrice, setXdaiPrice] = useState(0)
    const [telosPrice, setTelosPrice] = useState(0)
    const [cronosPrice, setCronosPrice] = useState(0)
    const [iotexPrice, setIotexPrice] = useState(0)
    const [fusePrice, setFusePrice] = useState(0)
    const [ethPrice, setEthPrice] = useState(0)
    const [arbitrumPrice, setArbitrumPrice] = useState(0)
    const [optimismPrice, setOptimismPrice] = useState(0)
    const [kavaPrice, setKavaPrice] = useState(0)
    const [bittorrentPrice, setBittorrentPrice] = useState(0)
    const [averagePrice, setAveragePrice] = useState(0)

    // charts
    const [dayData, setDayData] = useState([])
    const [dayTransfersData, setDayTransfersData] = useState([])

    // current transfers
    const [avaxTransfers, setAvaxTransfers] = useState(TRANSFER_TEMPLATE)
    const [bscTransfers, setBscTransfers] = useState(TRANSFER_TEMPLATE)
    const [hecoTransfers, setHecoTransfers] = useState(TRANSFER_TEMPLATE)
    const [ftmTransfers, setFtmTransfers] = useState(TRANSFER_TEMPLATE)
    const [maticTransfers, setMaticTransfers] = useState(TRANSFER_TEMPLATE)
    const [xdaiTransfers, setXdaiTransfers] = useState(TRANSFER_TEMPLATE)
    const [oneTransfers, setOneTransfers] = useState(TRANSFER_TEMPLATE)
    const [kccTransfers, setKccTransfers] = useState(TRANSFER_TEMPLATE)
    const [oktTransfers, setOktTransfers] = useState(TRANSFER_TEMPLATE)
    const [moonriverTransfers, setMoonriverTransfers] = useState(TRANSFER_TEMPLATE)
    const [elastosTransfers, setElastosTransfers] = useState(TRANSFER_TEMPLATE)
    const [hooTransfers, setHooTransfers] = useState(TRANSFER_TEMPLATE)
    const [telosTransfers, setTelosTransfers] = useState(TRANSFER_TEMPLATE)
    const [cronosTransfers, setCronosTransfers] = useState(TRANSFER_TEMPLATE)
    const [fuseTransfers, setFuseTransfers] = useState(TRANSFER_TEMPLATE)
    const [iotexTransfers, setIotexTransfers] = useState(TRANSFER_TEMPLATE)
    const [ethTransfers, setEthTransfers] = useState(TRANSFER_TEMPLATE)
    const [arbitrumTransfers, setArbitrumTransfers] = useState(TRANSFER_TEMPLATE)
    const [optimismTransfers, setOptimismTransfers] = useState(TRANSFER_TEMPLATE)
    const [kavaTransfers, setKavaTransfers] = useState(TRANSFER_TEMPLATE)
    const [bittorrentTransfers, setBittorrentTransfers] = useState(TRANSFER_TEMPLATE)
    const [totalTransfers, setTotalTransfers] = useState(TOTAL_TRANSFER_TEMPLATE)

    // elk net current
    const [elknetTransfers, setElknetTransfers] = useState(0)
    const [elknetTransferred, setElknetTransferred] = useState(0)
    const [elknetUsers, setElknetUsers] = useState(0)

    const [uniqueUsers, setUniqueUsers] = useState(0)
    const [currentChain, setCurrentChain] = useState(Chains.AVAX)
    const [currentChainPretty, setCurrentChainPretty] = useState("Avalanche")
    const [currentChainLogo, setCurrentChainLogo] = useState(ChainLogos.AVAX)
    const [currentChainPrice, setCurrentChainPrice] = useState(0)
    const [currentChainTransfersToday, setCurrentChainTransfersToday] = useState(TRANSFER_TEMPLATE)
    const [currentChainCirculatingSupply, setCurrentChainCirculatingSupply] = useState(0)
    const [currentChainMarketCap, setCurrentChainMarketCap] = useState(0)
    const [totalCirculatingSupply, setTotalCirculatingSupply] = useState(0)
    const [stakingRewards, setStakingRewards] = useState(0);

    // set chain avax initial
    useEffect(async () => {
        setCurrentChain(Chains.AVAX)
    }, [])

    // set average price
    useEffect(() => {
        let prices = [
            avaxPrice ?? 0,
            bscPrice ?? 0,
            ftmPrice ?? 0,
            maticPrice ?? 0,
            hecoPrice ?? 0,
            xdaiPrice ?? 0,
            moonriverPrice ?? 0,
            elastosPrice ?? 0,
            oktPrice ?? 0,
            kccPrice ?? 0,
            onePrice ?? 0,
            hooPrice ?? 0,
            telosPrice ?? 0,
            cronosPrice ?? 0,
            fusePrice ?? 0,
            iotexPrice ?? 0,
            ethPrice ?? 0,
            arbitrumPrice ?? 0,
            optimismPrice ?? 0,
            kavaPrice ?? 0,
            bittorrentPrice ?? 0
        ].filter(x => x !== 0)
        if (avaxPrice && bscPrice)
            setAveragePrice(
                _.meanBy(prices, price => price).toFixed(2) ?? 0
            )
    }, [avaxPrice, bscPrice, ftmPrice, hecoPrice, maticPrice, xdaiPrice, telosPrice, cronosPrice])

    useEffect(async () => {
        let elkNetData = await APIHelper.getElkNetHistorical();

        const [v1data, v2data] = await Promise.all([elkNetData.v1, elkNetData.v2])

        const v1keys = Object.keys(v1data.data["total"])
        const v1latest = _.max(v1keys)
        const v2keys = Object.keys(v2data.data["v2-" + "total"])
        const v2latest = _.max(v2keys)

        // GLOBAL TRANSFERS DONE / TRANSFERRED TOTAL
        const v1AmtTransfers = v1data?.data?.["total"]?.[`${v1latest}`]?.["amount-transfers"] ?? 0
        const v2AmtTransfers = v2data?.data?.["v2-total"]?.[`${v2latest}`]?.["amount-transfers"] ?? 0
        const amtTransfers = v1AmtTransfers + v2AmtTransfers

        const v1NumTransfers = v1data?.data?.["total"]?.[`${v1latest}`]?.["num-transfers"] ?? 0
        const v2NumTransfers = v2data?.data?.["v2-total"]?.[`${v2latest}`]?.["num-transfers"] ?? 0
        const numTransfers = v1NumTransfers + v2NumTransfers

        setTotalTransfers({
            "amount-transfers": amtTransfers,
            "num-transfers": numTransfers
        })

        const diffDataSets = (latest, v2data) => {

            // v1 not relevant as this calculates last 24h
            const v1AmountTransfersIn = 0 //v1data?.[`${latest}`]?.["amount-transfers-in"] ?? 0 - v1data?.[`${latest - 86400}`]["amount-transfers-in"] ?? 0
            const v1AmountTransfersOut = 0 //v1data?.[`${latest}`]?.["amount-transfers-out"] ?? 0 - v1data?.[`${latest - 86400}`]["amount-transfers-out"] ?? 0
            const v1NumberTransfersIn = 0 //v1data?.[`${latest}`]?.["num-transfers-in"] ?? 0 - v1data?.[`${latest - 86400}`]["num-transfers-in"] ?? 0
            const v1NumberTransfersOut = 0 //v1data?.[`${latest}`]?.["num-transfers-out"] ?? 0 - v1data?.[`${latest - 86400}`]["num-transfers-out"] ?? 0

            const v2AmountTransfersIn = v2data?.[`${latest}`]["amount-transfers-in"] - v2data?.[`${latest - 86400}`]["amount-transfers-in"]
            const v2AmountTransfersOut = v2data?.[`${latest}`]["amount-transfers-out"] - v2data?.[`${latest - 86400}`]["amount-transfers-out"]

            const v2NumberTransfersIn = v2data?.[`${latest}`]["num-transfers-in"] - v2data?.[`${latest - 86400}`]["num-transfers-in"]
            const v2NumberTransfersOut = v2data?.[`${latest}`]["num-transfers-out"] - v2data?.[`${latest - 86400}`]["num-transfers-out"]

            return {
                "amount-transfers-in": v2AmountTransfersIn,
                "amount-transfers-out": v2AmountTransfersOut,
                "num-transfers-in": v2NumberTransfersIn,
                "num-transfers-out": v2NumberTransfersOut
            };
        }

        setAvaxTransfers(diffDataSets(v2latest, v2data.data["v2-avax"]))
        setBscTransfers(diffDataSets(v2latest, v2data.data["v2-bsc"]))
        setFtmTransfers(diffDataSets(v2latest, v2data.data["v2-ftm"]))
        setHecoTransfers(diffDataSets(v2latest, v2data.data["v2-heco"]))
        setMaticTransfers(diffDataSets(v2latest, v2data.data["v2-matic"]))
        setXdaiTransfers(diffDataSets(v2latest, v2data.data["v2-xdai"]))
        setOneTransfers(diffDataSets(v2latest, v2data.data["v2-harmony"]))
        setKccTransfers(diffDataSets(v2latest, v2data.data["v2-kcc"]))
        setHooTransfers(diffDataSets(v2latest, v2data.data["v2-hoo"]))
        setOktTransfers(diffDataSets(v2latest, v2data.data["v2-okex"]))
        setElastosTransfers(diffDataSets(v2latest, v2data.data["v2-elastos"]))
        setMoonriverTransfers(diffDataSets(v2latest, v2data.data["v2-moonriver"]))
        setTelosTransfers(diffDataSets(v2latest, v2data.data["v2-telos"]))
        setCronosTransfers(diffDataSets(v2latest, v2data.data["v2-cronos"]))
        setFuseTransfers(diffDataSets(v2latest, v2data.data["v2-cronos"]))
        setIotexTransfers(diffDataSets(v2latest, v2data.data["v2-cronos"]))
        setEthTransfers(diffDataSets(v2latest, v2data.data["v2-ethereum"]))
        setArbitrumTransfers(diffDataSets(v2latest, v2data.data["v2-arbitrum"]))
        setOptimismTransfers(diffDataSets(v2latest, v2data.data["v2-optimism"]))
        setKavaTransfers(diffDataSets(v2latest, v2data.data["v2-kava"]))
        setBittorrentTransfers(diffDataSets(v2latest, v2data.data["v2-bittorrent"]))
    }, [])

    // unique users
    useEffect(async () => {
        const userCount = await ReportHelper.getUniqueUserCount()
        setUniqueUsers(parseInt(userCount))
    }, [])

    // currentchain pretty
    useEffect(() => {
        setCurrentChainPretty(getCurrentChainPretty(currentChain))
    }, [currentChain])
    const [totalMarketcap, setTotalMarketcap] = useState(0)
    //current
    useEffect(() => {
        setCurrentChainMarketCap(currentChainPrice * currentChainCirculatingSupply)
    }, [currentChainPrice, currentChainCirculatingSupply, currentChainMarketCap, setCurrentChainMarketCap])
    //total
    useEffect(() => {
        setTotalMarketcap(averagePrice * totalCirculatingSupply)
    }, [averagePrice, totalCirculatingSupply, setTotalMarketcap])

    // Daily volume data
    useEffect(async () => {
        setDayData([])
        try {
            let dd = await GraphHelper.getDayVolume(currentChain)
            let ddf = dd.data.data.elkDayDatas.map((k, v) => {
                if (k.date % (86400) === 0) {
                    k.dailyVolumeUSD = parseFloat(`${k.dailyVolumeUSD}`) * 0.003
                    k.date = new Date(k.date * 1000)
                    return k
                }
            })
            setDayData(ddf)
        } catch (e) {
        }
    }, [currentChain])

    // Day transfers to chain (chart)
    useEffect(async () => {
        setDayTransfersData([])
        try {
            let elkNetHistorical = await APIHelper.getElkNetHistorical()
            const [elkNetHistoricalV1, elkNetHistoricalV2] = await Promise.all([elkNetHistorical.v1, elkNetHistorical.v2])

            const lowerCaseCurrentChain = currentChain.toLocaleLowerCase()

            let v1Data = elkNetHistoricalV1?.["data"]?.[lowerCaseCurrentChain] ?? {}
            let v2Data = elkNetHistoricalV2?.["data"]?.["v2-" + lowerCaseCurrentChain] ?? {}

            //get all the dates
            let v1Keys = Object.keys(v1Data)
            let v2Keys = Object.keys(v2Data)

            // remove duplicate dates
            let keySet = [...new Set([...v1Keys, ...v2Keys])]
            const lastKey = keySet[keySet.length - 1]
            // remove all duplicate keys
            let v1ddf = keySet.map((k, v) => {
                if (v === lastKey) {
                    return {
                        "date": new Date(k * 1000),
                        "value": 0
                    }
                } else if (k % 86400 === 0) {
                    // only get data at midnight
                    // get current and previous values (or 0 if there's an error)
                    let v1Current = v1Data?.[k]?.["num-transfers-in"] ?? 0
                    let v2Current = v2Data?.[k]?.["num-transfers-in"] ?? 0
                    let _current = v1Current + v2Current

                    let _prev = 0, _prev1 = 0, _prev2 = 0;

                    _prev1 = v1Data?.[k - 86400]?.["num-transfers-in"] ?? 0
                    _prev2 = v2Data?.[k - 86400]?.["num-transfers-in"] ?? 0
                    _prev = _prev1 + _prev2

                    let value = _current - _prev
                    value = value < 0 ? 0 : value;

                    return {
                        "date": new Date(k * 1000),
                        "value": value
                    }
                }
            })

            setDayTransfersData(v1ddf)
        } catch (e) {
        }
    }, [currentChain])

    const toggleChain = (event) => {
        setCurrentChain(event.target.id)
    }

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    // set prices
    useEffect(() => {
        Promise.all(
            [
                ReportHelper.getPrice(Chains.AVAX)
                    .then(r => setAvaxPrice(parseFloat(r.toString()))),
                ReportHelper.getPrice(Chains.BSC)
                    .then(r => setBscPrice(parseFloat(r.toString()))),
                ReportHelper.getPrice(Chains.FTM)
                    .then(r => setFtmPrice(parseFloat(r.toString()))),
                ReportHelper.getPrice(Chains.HECO)
                    .then(r => setHecoPrice(parseFloat(r.toString()))),
                ReportHelper.getPrice(Chains.MATIC)
                    .then(r => setMaticPrice(parseFloat(r.toString()))),
                ReportHelper.getPrice(Chains.XDAI)
                    .then(r => setXdaiPrice(parseFloat(r.toString()))),
                //ReportHelper.getPrice(Chains.HARMONY)
                //    .then(r => setOnePrice(parseFloat(r.toString()))),
                ReportHelper.getPrice(Chains.MOONRIVER)
                    .then(r => setMoonriverPrice(parseFloat(r.toString()))),
                ReportHelper.getPrice(Chains.KCC)
                    .then(r => setKccPrice(parseFloat(r.toString()))),
                //ReportHelper.getPrice(Chains.ELASTOS)
                //    .then(r => setElastosPrice(parseFloat(r.toString()))),
                ReportHelper.getPrice(Chains.OKEX)
                    .then(r => setOktPrice(parseFloat(r.toString()))),
                ReportHelper.getPrice(Chains.HOO)
                    .then(r => setHooPrice(parseFloat(r.toString()))),
                //ReportHelper.getPrice(Chains.TELOS)
                //    .then(r => setTelosPrice(parseFloat(r.toString()))),
                ReportHelper.getPrice(Chains.CRONOS)
                    .then(r => setCronosPrice(parseFloat(r.toString()))),
                ReportHelper.getPrice(Chains.FUSE)
                    .then(r => setFusePrice(parseFloat(r.toString()))),
                ReportHelper.getPrice(Chains.IOTEX)
                    .then(r => setIotexPrice(parseFloat(r.toString()))),
                ReportHelper.getPrice(Chains.ETHEREUM)
                    .then(r => setEthPrice(parseFloat(r.toString()))),
                ReportHelper.getPrice(Chains.OPTIMISM)
                    .then(r => setOptimismPrice(parseFloat(r.toString()))),
                ReportHelper.getPrice(Chains.KAVA)
                    .then(r => setKavaPrice(parseFloat(r.toString()))),
                ReportHelper.getPrice(Chains.BITTORRENT)
                    .then(r => setBittorrentPrice(parseFloat(r.toString()))),
                ReportHelper.getPrice(Chains.ARBITRUM)
                    .then(r => setArbitrumPrice(parseFloat(r.toString()))),
            ]
        )
    }, [avaxPrice])

    // ELKNET TRANSFERS
    useEffect(async () => {
        const elknet = await APIHelper.getElkNet()
        const [totalv1, totalv2] = await Promise.all([elknet.v1, elknet.v2])

        const v1Amount = totalv1?.["data"]?.[`${currentChain.toLowerCase()}`]?.["amount-transfers-in"] ?? 0
        const v2Amount = totalv2?.["data"]?.[`v2-${currentChain.toLowerCase()}`]?.["amount-transfers-in"] ?? 0
        const amount = v1Amount + v2Amount
        setElknetTransferred(amount);

        // ELK NET TRANSFERRED
        const v1Transferred = totalv1?.["data"]?.[`${currentChain.toLocaleLowerCase()}`]?.["num-transfers-in"] ?? 0
        const v2Transferred = totalv2?.["data"]?.[`v2-${currentChain.toLocaleLowerCase()}`]?.["num-transfers-in"] ?? 0
        const trnsfrd = v1Transferred + v2Transferred
        setElknetTransfers(trnsfrd);

        console.log(trnsfrd)
    }, [currentChain])

    useEffect(async () => {
        switch (currentChain) {
            case (Chains.AVAX):
                setCurrentChainLogo(ChainLogos[currentChain])
                setCurrentChainPrice(avaxPrice)
                setCurrentChainTransfersToday(avaxTransfers)
                break
            case (Chains.BSC):
                setCurrentChainLogo(ChainLogos[currentChain])
                setCurrentChainPrice(bscPrice)
                setCurrentChainTransfersToday(bscTransfers)
                break
            case (Chains.FTM):
                setCurrentChainLogo(ChainLogos[currentChain])
                setCurrentChainPrice(ftmPrice)
                setCurrentChainTransfersToday(ftmTransfers)
                break
            case (Chains.HECO):
                setCurrentChainLogo(ChainLogos[currentChain])
                setCurrentChainPrice(hecoPrice)
                setCurrentChainTransfersToday(hecoTransfers)
                break
            case (Chains.MATIC):
                setCurrentChainLogo(ChainLogos[currentChain])
                setCurrentChainPrice(maticPrice)
                setCurrentChainTransfersToday(maticTransfers)
                break
            case (Chains.HOO):
                setCurrentChainLogo(ChainLogos[currentChain])
                setCurrentChainPrice(hooPrice)
                setCurrentChainTransfersToday(hooTransfers)
                break
            case (Chains.MOONRIVER):
                setCurrentChainLogo(ChainLogos[currentChain])
                setCurrentChainPrice(moonriverPrice)
                setCurrentChainTransfersToday(moonriverTransfers)
                break
            case (Chains.ELASTOS):
                setCurrentChainLogo(ChainLogos[currentChain])
                setCurrentChainPrice(elastosPrice)
                setCurrentChainTransfersToday(elastosTransfers)
                break
            case (Chains.OKEX):
                setCurrentChainLogo(ChainLogos[currentChain])
                setCurrentChainPrice(oktPrice)
                setCurrentChainTransfersToday(oktTransfers)
                break
            case (Chains.KCC):
                setCurrentChainLogo(ChainLogos[currentChain])
                setCurrentChainPrice(kccPrice)
                setCurrentChainTransfersToday(kccTransfers)
                break
            case (Chains.HARMONY):
                setCurrentChainLogo(ChainLogos[currentChain])
                setCurrentChainPrice(onePrice)
                setCurrentChainTransfersToday(oneTransfers)
                break
            case (Chains.XDAI):
                setCurrentChainLogo(ChainLogos[currentChain])
                setCurrentChainPrice(xdaiPrice)
                setCurrentChainTransfersToday(xdaiTransfers)
                break
            case (Chains.TELOS):
                setCurrentChainLogo(ChainLogos[currentChain])
                setCurrentChainPrice(telosPrice)
                setCurrentChainTransfersToday(telosTransfers)
                break
            case (Chains.CRONOS):
                setCurrentChainLogo(ChainLogos[currentChain])
                setCurrentChainPrice(cronosPrice)
                setCurrentChainTransfersToday(cronosTransfers)
                break
            case (Chains.FUSE):
                setCurrentChainLogo(ChainLogos[currentChain])
                setCurrentChainPrice(fusePrice)
                setCurrentChainTransfersToday(fuseTransfers)
                break
            case (Chains.IOTEX):
                setCurrentChainLogo(ChainLogos[currentChain])
                setCurrentChainPrice(iotexPrice)
                setCurrentChainTransfersToday(iotexTransfers)
                break
            case (Chains.ETHEREUM):
                setCurrentChainLogo(ChainLogos[currentChain])
                setCurrentChainPrice(ethPrice)
                setCurrentChainTransfersToday(ethTransfers)
                break
            case (Chains.ARBITRUM):
                setCurrentChainLogo(ChainLogos[currentChain])
                setCurrentChainPrice(arbitrumPrice)
                setCurrentChainTransfersToday(arbitrumTransfers)
                break
            case (Chains.OPTIMISM):
                setCurrentChainLogo(ChainLogos[currentChain])
                setCurrentChainPrice(optimismPrice)
                setCurrentChainTransfersToday(optimismTransfers)
                break
            case (Chains.KAVA):
                setCurrentChainLogo(ChainLogos[currentChain])
                setCurrentChainPrice(kavaPrice)
                setCurrentChainTransfersToday(kavaTransfers)
                break
            case (Chains.BITTORRENT):
                setCurrentChainLogo(ChainLogos[currentChain])
                setCurrentChainPrice(bittorrentPrice)
                setCurrentChainTransfersToday(bittorrentTransfers)
                break
        }
        try {
            const circulationData = await APIHelper.getCirculation()
            const [v1circulation, v2circulation] = await Promise.all([circulationData.v1, circulationData.v2])

            const totalCirculatingSupply = v1circulation?.data?.total + v2circulation?.data?.total
            setTotalCirculatingSupply(totalCirculatingSupply)

            const currentChainCirculatingSupply = (v2circulation.data[currentChain.toLowerCase()])
            setCurrentChainCirculatingSupply(currentChainCirculatingSupply)

            Web3Helper.getSingleStakingReward(currentChain).then(r => setStakingRewards(r))
        } catch (e) {
        }
        //        leave avaxPrice; initial load returns 0 and doesn't refresh
    }, [currentChain, avaxPrice])

    return <SkeletonTheme baseColor={"#192225"} highlightColor={"#444"}>
        <Helmet>
            <meta charSet="utf-8"/>
            <title>Elk Finance: ElkNet Dashboard</title>
        </Helmet>
        <Header toggleChain={toggleChain} currentChain={currentChain} setCurrentChain={setCurrentChain}
                currentChainLogo={currentChainLogo} currentChainPretty={currentChainPretty}/>
        <div className="container">
            <DashboardMetrics {...{
                uniqueUsers,
                averagePrice,
                totalTransfers,
                totalMarketcap,
                currentChain,
                currentChainPrice,
                currentChainCirculatingSupply,
                stakingRewards,
                currentChainTransfersToday,
                currentChainMarketCap,
                elknetTransfers,
                elknetTransferred,
                elknetUsers
            }}/>
            <TotalValueMovedCrossChain elknetTransferred={elknetTransferred} averagePrice={averagePrice}/>
            <div className="row mb-1">
                <h1>Elk Analytics</h1>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <h3>Fees per day</h3>
                    <ChartFeesPerDay dayData={dayData} onlyUnique={onlyUnique}/>
                </div>
                <div className="col-lg-6">
                    <h3>Elknet transfers to {currentChainPretty}</h3>
                    <ChartTransfers dayTransfersData={dayTransfersData} dayData={dayData} onlyUnique={onlyUnique}/>
                </div>
            </div>
        </div>
    </SkeletonTheme>
}


export default App;