import {ethers} from "ethers";
import Web3 from "web3";
import {Chains} from "../consts/Chains.js";

export default class Web3Helper {
    static providers = {
        "AVAX": new ethers.providers.StaticJsonRpcProvider("https://api.avax.network/ext/bc/C/rpc", {
            chainId: 43114,
            name: "avaxc"
        }),
        "FTM": new ethers.providers.StaticJsonRpcProvider("https://rpc.ftm.tools", {
            chainId: 250,
            name: "fantom"
        }),
        "HECO": new ethers.providers.StaticJsonRpcProvider("https://http-mainnet-node.huobichain.com", {
            chainId: 128,
            name: "heco"
        }),
        "MATIC": new ethers.providers.StaticJsonRpcProvider("https://rpc-mainnet.maticvigil.com", {
            chainId: 137,
            name: "matic"
        }),
        "XDAI": new ethers.providers.StaticJsonRpcProvider("https://rpc.xdaichain.com", {
            chainId: 0x64,
            name: "xdai"
        }),
        "MOONRIVER": new ethers.providers.StaticJsonRpcProvider("https://rpc.moonriver.moonbeam.network", {
            chainId: 1285,
            name: "movr"
        }),
        "KCC": new ethers.providers.StaticJsonRpcProvider("https://rpc.kcc.network", {
            chainId: 321,
            name: "kcc"
        }),
        "OKT": new ethers.providers.StaticJsonRpcProvider("https://exchainrpc.okex.org", {
            chainId: 66,
            name: "okt"
        }),
        "ELASTOS": new ethers.providers.StaticJsonRpcProvider("https://api.elastos.io/eth", {
            chainId: 20,
            name: "ela"
        }),
        "HARMONY": new ethers.providers.StaticJsonRpcProvider("https://api.harmony.one", {
            chainId: 1666600000,
            name: "one"
        }),
        "HOO": new ethers.providers.StaticJsonRpcProvider("https://http-mainnet.hoosmartchain.com", {
            chainId: 70,
            name: "hoo"
        }),
        "TELOS": new ethers.providers.StaticJsonRpcProvider("https://mainnet.telos.net/evm", {
            chainId: 40,
            name: "telos"
        }),
        "CRONOS": new ethers.providers.StaticJsonRpcProvider("https://evm-cronos.crypto.org", {
            chainId: 25,
            name: "cro"
        }),
        "FUSE": new ethers.providers.StaticJsonRpcProvider("https://rpc.fuse.io/", {
            chainId: 122,
            name: "fuse"
        }),
        "IOTEX": new ethers.providers.StaticJsonRpcProvider("https://babel-api.mainnet.iotex.io/", {
            chainId: 4689,
            name: "iotex"
        }),
        "ETHEREUM": new ethers.providers.StaticJsonRpcProvider("https://rpc.ankr.com/eth", {
            chainId: 1,
            name: "eth"
        }),
        "ARBITRUM": new ethers.providers.StaticJsonRpcProvider("https://rpc.ankr.com/arbitrum", {
            chainId: 42161,
            name: "eth"
        }),
        "OPTIMISM": new ethers.providers.StaticJsonRpcProvider("https://mainnet.optimism.io/", {
            chainId: 4689,
            name: "10"
        }),
        "KAVA": new ethers.providers.StaticJsonRpcProvider("https://evm.kava.io/", {
            chainId: 2222,
            name: "kava"
        }),
        "BITTORRENT": new ethers.providers.StaticJsonRpcProvider("https://rpc.bt.io/", {
            chainId: 199,
            name: "btt"
        }),
    }

    static singleStakingABI = [{
        "type": "constructor",
        "stateMutability": "nonpayable",
        "inputs": [{"type": "address", "name": "_rewardsToken", "internalType": "address"}, {
            "type": "address",
            "name": "_stakingToken",
            "internalType": "address"
        }, {"type": "address", "name": "_boosterToken", "internalType": "address"}, {
            "type": "uint256",
            "name": "_rewardsDuration",
            "internalType": "uint256"
        }, {"type": "uint256[]", "name": "_feeSchedule", "internalType": "uint256[]"}, {
            "type": "uint256[]",
            "name": "_withdrawalFeesPct",
            "internalType": "uint256[]"
        }]
    }, {
        "type": "event",
        "name": "BoosterRewardPaid",
        "inputs": [{"type": "address", "name": "user", "internalType": "address", "indexed": true}, {
            "type": "uint256",
            "name": "reward",
            "internalType": "uint256",
            "indexed": false
        }],
        "anonymous": false
    }, {
        "type": "event",
        "name": "BoosterRewardSet",
        "inputs": [{"type": "address", "name": "token", "internalType": "address", "indexed": false}],
        "anonymous": false
    }, {
        "type": "event",
        "name": "CoveragePaid",
        "inputs": [{"type": "address", "name": "user", "internalType": "address", "indexed": true}, {
            "type": "uint256",
            "name": "amount",
            "internalType": "uint256",
            "indexed": false
        }],
        "anonymous": false
    }, {
        "type": "event",
        "name": "FeesCollected",
        "inputs": [{"type": "address", "name": "user", "internalType": "address", "indexed": true}, {
            "type": "uint256",
            "name": "amount",
            "internalType": "uint256",
            "indexed": false
        }],
        "anonymous": false
    }, {
        "type": "event",
        "name": "FeesRecovered",
        "inputs": [{"type": "uint256", "name": "amount", "internalType": "uint256", "indexed": false}],
        "anonymous": false
    }, {
        "type": "event",
        "name": "LeftoverBoosterRecovered",
        "inputs": [{"type": "uint256", "name": "amount", "internalType": "uint256", "indexed": false}],
        "anonymous": false
    }, {
        "type": "event",
        "name": "LeftoverRewardRecovered",
        "inputs": [{"type": "uint256", "name": "amount", "internalType": "uint256", "indexed": false}],
        "anonymous": false
    }, {
        "type": "event",
        "name": "OwnershipTransferred",
        "inputs": [{
            "type": "address",
            "name": "previousOwner",
            "internalType": "address",
            "indexed": true
        }, {"type": "address", "name": "newOwner", "internalType": "address", "indexed": true}],
        "anonymous": false
    }, {
        "type": "event",
        "name": "Paused",
        "inputs": [{"type": "address", "name": "account", "internalType": "address", "indexed": false}],
        "anonymous": false
    }, {
        "type": "event",
        "name": "Recovered",
        "inputs": [{
            "type": "address",
            "name": "token",
            "internalType": "address",
            "indexed": false
        }, {"type": "uint256", "name": "amount", "internalType": "uint256", "indexed": false}],
        "anonymous": false
    }, {
        "type": "event",
        "name": "RewardPaid",
        "inputs": [{"type": "address", "name": "user", "internalType": "address", "indexed": true}, {
            "type": "uint256",
            "name": "reward",
            "internalType": "uint256",
            "indexed": false
        }],
        "anonymous": false
    }, {
        "type": "event",
        "name": "RewardsDurationUpdated",
        "inputs": [{"type": "uint256", "name": "newDuration", "internalType": "uint256", "indexed": false}],
        "anonymous": false
    }, {
        "type": "event",
        "name": "RewardsEmissionEnded",
        "inputs": [{"type": "uint256", "name": "amount", "internalType": "uint256", "indexed": false}],
        "anonymous": false
    }, {
        "type": "event",
        "name": "RewardsEmissionStarted",
        "inputs": [{
            "type": "uint256",
            "name": "reward",
            "internalType": "uint256",
            "indexed": false
        }, {
            "type": "uint256",
            "name": "boosterReward",
            "internalType": "uint256",
            "indexed": false
        }, {"type": "uint256", "name": "duration", "internalType": "uint256", "indexed": false}],
        "anonymous": false
    }, {
        "type": "event",
        "name": "Staked",
        "inputs": [{"type": "address", "name": "user", "internalType": "address", "indexed": true}, {
            "type": "uint256",
            "name": "amount",
            "internalType": "uint256",
            "indexed": false
        }],
        "anonymous": false
    }, {
        "type": "event",
        "name": "Unpaused",
        "inputs": [{"type": "address", "name": "account", "internalType": "address", "indexed": false}],
        "anonymous": false
    }, {
        "type": "event",
        "name": "WithdrawalFeesSet",
        "inputs": [{
            "type": "uint256[]",
            "name": "_feeSchedule",
            "internalType": "uint256[]",
            "indexed": false
        }, {"type": "uint256[]", "name": "_withdrawalFees", "internalType": "uint256[]", "indexed": false}],
        "anonymous": false
    }, {
        "type": "event",
        "name": "Withdrawn",
        "inputs": [{"type": "address", "name": "user", "internalType": "address", "indexed": true}, {
            "type": "uint256",
            "name": "amount",
            "internalType": "uint256",
            "indexed": false
        }],
        "anonymous": false
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "uint256", "name": "", "internalType": "uint256"}],
        "name": "balanceOf",
        "inputs": [{"type": "address", "name": "account", "internalType": "address"}]
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "uint256", "name": "", "internalType": "uint256"}],
        "name": "boosterEarned",
        "inputs": [{"type": "address", "name": "account", "internalType": "address"}]
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "uint256", "name": "", "internalType": "uint256"}],
        "name": "boosterRewardPerToken",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "uint256", "name": "", "internalType": "uint256"}],
        "name": "boosterRewardPerTokenStored",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "uint256", "name": "", "internalType": "uint256"}],
        "name": "boosterRewardRate",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "uint256", "name": "", "internalType": "uint256"}],
        "name": "boosterRewards",
        "inputs": [{"type": "address", "name": "", "internalType": "address"}]
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "address", "name": "", "internalType": "contract IERC20"}],
        "name": "boosterToken",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "uint256", "name": "", "internalType": "uint256"}],
        "name": "coverageOf",
        "inputs": [{"type": "address", "name": "account", "internalType": "address"}]
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "uint256", "name": "", "internalType": "uint256"}],
        "name": "coverages",
        "inputs": [{"type": "address", "name": "", "internalType": "address"}]
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "uint256", "name": "", "internalType": "uint256"}],
        "name": "earned",
        "inputs": [{"type": "address", "name": "account", "internalType": "address"}]
    }, {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "emergencyWithdraw",
        "inputs": [{"type": "uint256", "name": "amount", "internalType": "uint256"}]
    }, {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "exit",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "uint256", "name": "", "internalType": "uint256"}],
        "name": "exitFee",
        "inputs": [{"type": "address", "name": "account", "internalType": "address"}]
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "uint256", "name": "", "internalType": "uint256"}],
        "name": "fee",
        "inputs": [{"type": "address", "name": "account", "internalType": "address"}, {
            "type": "uint256",
            "name": "withdrawalAmount",
            "internalType": "uint256"
        }]
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "uint256", "name": "", "internalType": "uint256"}],
        "name": "feeSchedule",
        "inputs": [{"type": "uint256", "name": "", "internalType": "uint256"}]
    }, {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "getBoosterReward",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "uint256", "name": "", "internalType": "uint256"}],
        "name": "getBoosterRewardForDuration",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "getCoverage",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "getReward",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "uint256", "name": "", "internalType": "uint256"}],
        "name": "getRewardForDuration",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "uint256", "name": "", "internalType": "uint256"}],
        "name": "lastStakedTime",
        "inputs": [{"type": "address", "name": "", "internalType": "address"}]
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "uint256", "name": "", "internalType": "uint256"}],
        "name": "lastTimeRewardApplicable",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "uint256", "name": "", "internalType": "uint256"}],
        "name": "lastUpdateTime",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "address", "name": "", "internalType": "address"}],
        "name": "owner",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "pause",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "bool", "name": "", "internalType": "bool"}],
        "name": "paused",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "uint256", "name": "", "internalType": "uint256"}],
        "name": "periodFinish",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "recoverERC20",
        "inputs": [{"type": "address", "name": "tokenAddress", "internalType": "address"}, {
            "type": "uint256",
            "name": "tokenAmount",
            "internalType": "uint256"
        }]
    }, {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "recoverFees",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "recoverLeftoverBooster",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "recoverLeftoverReward",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "renounceOwnership",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "uint256", "name": "", "internalType": "uint256"}],
        "name": "rewardPerToken",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "uint256", "name": "", "internalType": "uint256"}],
        "name": "rewardPerTokenStored",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "uint256", "name": "", "internalType": "uint256"}],
        "name": "rewardRate",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "uint256", "name": "", "internalType": "uint256"}],
        "name": "rewards",
        "inputs": [{"type": "address", "name": "", "internalType": "address"}]
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "uint256", "name": "", "internalType": "uint256"}],
        "name": "rewardsDuration",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "address", "name": "", "internalType": "contract IERC20"}],
        "name": "rewardsToken",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "sendRewardsAndStartEmission",
        "inputs": [{"type": "uint256", "name": "reward", "internalType": "uint256"}, {
            "type": "uint256",
            "name": "boosterReward",
            "internalType": "uint256"
        }, {"type": "uint256", "name": "duration", "internalType": "uint256"}]
    }, {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "setBoosterReward",
        "inputs": [{"type": "address", "name": "addr", "internalType": "address"}, {
            "type": "uint256",
            "name": "amount",
            "internalType": "uint256"
        }]
    }, {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "setBoosterRewards",
        "inputs": [{"type": "address[]", "name": "addresses", "internalType": "address[]"}, {
            "type": "uint256[]",
            "name": "amounts",
            "internalType": "uint256[]"
        }]
    }, {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "setBoosterToken",
        "inputs": [{"type": "address", "name": "_boosterToken", "internalType": "address"}]
    }, {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "setCoverageAmount",
        "inputs": [{"type": "address", "name": "addr", "internalType": "address"}, {
            "type": "uint256",
            "name": "amount",
            "internalType": "uint256"
        }]
    }, {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "setCoverageAmounts",
        "inputs": [{"type": "address[]", "name": "addresses", "internalType": "address[]"}, {
            "type": "uint256[]",
            "name": "amounts",
            "internalType": "uint256[]"
        }]
    }, {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "setReward",
        "inputs": [{"type": "address", "name": "addr", "internalType": "address"}, {
            "type": "uint256",
            "name": "amount",
            "internalType": "uint256"
        }]
    }, {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "setRewards",
        "inputs": [{"type": "address[]", "name": "addresses", "internalType": "address[]"}, {
            "type": "uint256[]",
            "name": "amounts",
            "internalType": "uint256[]"
        }]
    }, {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "setRewardsDuration",
        "inputs": [{"type": "uint256", "name": "duration", "internalType": "uint256"}]
    }, {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "setWithdrawalFees",
        "inputs": [{"type": "uint256[]", "name": "_feeSchedule", "internalType": "uint256[]"}, {
            "type": "uint256[]",
            "name": "_withdrawalFees",
            "internalType": "uint256[]"
        }]
    }, {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "stake",
        "inputs": [{"type": "uint256", "name": "amount", "internalType": "uint256"}]
    }, {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "stakeWithPermit",
        "inputs": [{"type": "uint256", "name": "amount", "internalType": "uint256"}, {
            "type": "uint256",
            "name": "deadline",
            "internalType": "uint256"
        }, {"type": "uint8", "name": "v", "internalType": "uint8"}, {
            "type": "bytes32",
            "name": "r",
            "internalType": "bytes32"
        }, {"type": "bytes32", "name": "s", "internalType": "bytes32"}]
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "address", "name": "", "internalType": "contract IERC20"}],
        "name": "stakingToken",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "startEmission",
        "inputs": [{"type": "uint256", "name": "reward", "internalType": "uint256"}, {
            "type": "uint256",
            "name": "boosterReward",
            "internalType": "uint256"
        }, {"type": "uint256", "name": "duration", "internalType": "uint256"}]
    }, {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "stopEmission",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "uint256", "name": "", "internalType": "uint256"}],
        "name": "totalCoverage",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "uint256", "name": "", "internalType": "uint256"}],
        "name": "totalFees",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "uint256", "name": "", "internalType": "uint256"}],
        "name": "totalSupply",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "transferOwnership",
        "inputs": [{"type": "address", "name": "newOwner", "internalType": "address"}]
    }, {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "unpause",
        "inputs": []
    }, {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "updateLastTime",
        "inputs": [{"type": "uint256", "name": "timestamp", "internalType": "uint256"}]
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "uint256", "name": "", "internalType": "uint256"}],
        "name": "userBoosterRewardPerTokenPaid",
        "inputs": [{"type": "address", "name": "", "internalType": "address"}]
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "uint256", "name": "", "internalType": "uint256"}],
        "name": "userRewardPerTokenPaid",
        "inputs": [{"type": "address", "name": "", "internalType": "address"}]
    }, {
        "type": "function",
        "stateMutability": "nonpayable",
        "outputs": [],
        "name": "withdraw",
        "inputs": [{"type": "uint256", "name": "amount", "internalType": "uint256"}]
    }, {
        "type": "function",
        "stateMutability": "view",
        "outputs": [{"type": "uint256", "name": "", "internalType": "uint256"}],
        "name": "withdrawalFeesPct",
        "inputs": [{"type": "uint256", "name": "", "internalType": "uint256"}]
    }];

    static singleStakingContracts = {
        "AVAX": () => new ethers.Contract("0x57A1CE7686F3B2AB61F5191c76361F985b57E0fa", this.singleStakingABI, this.providers["AVAX"]),
        "FTM": () => new ethers.Contract("0x57A1CE7686F3B2AB61F5191c76361F985b57E0fa", this.singleStakingABI, this.providers["FTM"]),
        "HECO": () => new ethers.Contract("0x57A1CE7686F3B2AB61F5191c76361F985b57E0fa", this.singleStakingABI, this.providers["HECO"]),
        "MATIC": () => new ethers.Contract("0x57A1CE7686F3B2AB61F5191c76361F985b57E0fa", this.singleStakingABI, this.providers["MATIC"]),
        "XDAI": () => new ethers.Contract("0x57A1CE7686F3B2AB61F5191c76361F985b57E0fa", this.singleStakingABI, this.providers["XDAI"]),
        "BSC": () => new ethers.Contract("0x57A1CE7686F3B2AB61F5191c76361F985b57E0fa", this.singleStakingABI, this.providers["BSC"]),
        "MOONRIVER": () => new ethers.Contract("0x57A1CE7686F3B2AB61F5191c76361F985b57E0fa", this.singleStakingABI, this.providers["MOONRIVER"]),
        "KCC": () => new ethers.Contract("0x57A1CE7686F3B2AB61F5191c76361F985b57E0fa", this.singleStakingABI, this.providers["KCC"]),
        "HARMONY": () => new ethers.Contract("0x57A1CE7686F3B2AB61F5191c76361F985b57E0fa", this.singleStakingABI, this.providers["ONE"]),
        "ELASTOS": () => new ethers.Contract("0x57A1CE7686F3B2AB61F5191c76361F985b57E0fa", this.singleStakingABI, this.providers["ELASTOS"]),
        "OKT": () => new ethers.Contract("0x57A1CE7686F3B2AB61F5191c76361F985b57E0fa", this.singleStakingABI, this.providers["OKT"]),
        "HOO": () => new ethers.Contract("0x57A1CE7686F3B2AB61F5191c76361F985b57E0fa", this.singleStakingABI, this.providers["HOO"]),
        "CRONOS": () => new ethers.Contract("0x57A1CE7686F3B2AB61F5191c76361F985b57E0fa", this.singleStakingABI, this.providers["CRONOS"]),
        "TELOS": () => new ethers.Contract("0x57A1CE7686F3B2AB61F5191c76361F985b57E0fa", this.singleStakingABI, this.providers["TELOS"]),
        "FUSE": () => new ethers.Contract("0x57A1CE7686F3B2AB61F5191c76361F985b57E0fa", this.singleStakingABI, this.providers["FUSE"]),
        "IOTEX": () => new ethers.Contract("0x57A1CE7686F3B2AB61F5191c76361F985b57E0fa", this.singleStakingABI, this.providers["IOTEX"]),
        "ETHEREUM": () => new ethers.Contract("0x57A1CE7686F3B2AB61F5191c76361F985b57E0fa", this.singleStakingABI, this.providers["ETHEREUM"]),
        "ARBITRUM": () => new ethers.Contract("0x57A1CE7686F3B2AB61F5191c76361F985b57E0fa", this.singleStakingABI, this.providers["ARBITRUM"]),
        "OPTIMISM": () => new ethers.Contract("0x57A1CE7686F3B2AB61F5191c76361F985b57E0fa", this.singleStakingABI, this.providers["OPTIMISM"]),
        "KAVA": () => new ethers.Contract("0x57A1CE7686F3B2AB61F5191c76361F985b57E0fa", this.singleStakingABI, this.providers["KAVA"]),
        "BITTORRENT": () => new ethers.Contract("0x57A1CE7686F3B2AB61F5191c76361F985b57E0fa", this.singleStakingABI, this.providers["BITTORRENT"]),
    }

    static async getSingleStakingReward(chain = Chains.AVAX) {
        try {
            let contract = Web3Helper.singleStakingContracts[`${chain}`];
            let rewardRate = await contract().functions.rewardRate().then(r => r);
            let rewardRate_eth = Web3.utils.fromWei(`${rewardRate[0]}`, "ether");
            let totalSupply = await contract().functions.totalSupply().then(r => r);
            let totalSupply_eth = Web3.utils.fromWei(`${totalSupply[0]}`, "ether");
            return (rewardRate_eth * 60 * 60 * 24 * 365 * 100 / totalSupply_eth).toFixed(2).toLocaleLowerCase() ?? 0;
        } catch (e) {
            console.log("getSingleStakingReward: Contract error.")
        }
    }
}