const axios = require("axios");
const _ = require("lodash");

export default class APIHelper {
    static get = async (suffix) => {
        let data = axios.get(`https://api.elk.finance/v2/info/${suffix}`).then(r => r);
        return data;
    }
    static getFarms = async () => {
        return {
            v1: axios.get(`https://api.elk.finance/v1/info/farms`).then(r => r),
            v2: axios.get(`https://api.elk.finance/v2/info/farms`).then(r => r)
        }
    }
    static getStakingPools = async () => {
        return {
            v1: axios.get(`https://api.elk.finance/v1/info/pools`).then(r => r),
            v2: axios.get(`https://api.elk.finance/v2/info/pools`).then(r => r)
        }
    }
    static getLatestPools = async () => {
        return {
            v1: axios.get(`https://api.elk.finance/v1/info/latest-pools`).then(r => r),
            v2: axios.get(`https://api.elk.finance/v2/info/latest-pools`).then(r => r)
        }
    }
    static getPairs = async () => {
        return {
            v1: axios.get(`https://api.elk.finance/v1/info/pairs`).then(r => r),
            v2: axios.get(`https://api.elk.finance/v2/info/pairs`).then(r => r)
        }
    }
    static getCirculation = async (chain = "total") => {
        chain = _.toLower(chain)
        return {
            v1: axios.get(`https://api.elk.finance/v1/info/circulation`).then(r => r),
            v2: axios.get(`https://api.elk.finance/v2/info/circulation`).then(r => r)
        }
    }
    static getElkNet = async () => {
        return {
            v1: axios.get(`https://api.elk.finance/v1/info/elknet`).then(r => r),
            v2: axios.get(`https://api.elk.finance/v2/info/elknet`).then(r => r)
        }
    }

    static getElkNetHistorical = async () => {
        return {
            v1: axios.get(`https://api.elk.finance/v1/info/elknet-historical`).then(r => r),
            v2: axios.get(`https://api.elk.finance/v2/info/elknet-historical`).then(r => r)
        }
    }
    static getApiStatus = async () => {
        return {
            v1: axios.get(`https://api.elk.finance/v1/info/status`).then(r => r),
            v2: axios.get(`https://api.elk.finance/v2/info/status`).then(r => r)
        }
    }
}
