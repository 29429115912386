import {Dropdown} from "react-bootstrap";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import {ChainLogos, Chains} from "../consts/Chains";
import Skeleton from "react-loading-skeleton";
import React from "react";
import {getCurrentChainPretty} from "../helpers/ChainHelper";

export function ChainToggler({currentChain, setCurrentChain, toggleChain, currentChainLogo}) {
    return <Dropdown>
        <DropdownToggle id="dropdown-basic" className={"text-left w-100 d-flex dropdown-toggle rounded-0 shadow"}
                        block data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        variant={"outline-light"}>
                                        <span
                                            className="w-100 text-left d-flex flex-row align-items-center px-4 p-2 text-truncate">
                                        <img src={currentChainLogo}
                                             className={"me-3 mb-1"}
                                             height={16}
                                             alt={`${Chains.AVAX} logo`}/> <span
                                            className={"text-truncate"}>{currentChain ? getCurrentChainPretty(currentChain) :
                                            <Skeleton/>}</span>
                                        </span>
            <span className={"mt-2"}>
                                        <i className="fas fa-chevron-down pe-2"> </i>
                                        </span></DropdownToggle>
        <Dropdown.Menu id="chainToggler" className="border-white w-92 bg-dark-2 rounded-0">
            {Object.keys(Chains).map(chain => {
                let tempChain = Chains[chain];
                return <Dropdown.Item key={chain} onClick={() => setCurrentChain(Chains[chain])}
                                      className={"justify-content-start px-4 p-2 border-bottom  border-secondary"}>
                    <img src={ChainLogos[tempChain]} className={"me-3 rounded-circle"} height={16}
                         alt={`${Chains[tempChain]} logo`}/> {getCurrentChainPretty(tempChain)}
                </Dropdown.Item>
            })}

        </Dropdown.Menu>
    </Dropdown>
}