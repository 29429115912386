import {Chains} from "../consts/Chains";

export function getCurrentChainPretty(chainSymbol) {
    switch (chainSymbol) {
        case (Chains.AVAX):
            return ("Avalanche")
        case (Chains.BSC):
            return ("Binance Smart Chain")
        case (Chains.FTM):
            return ("Fantom")
        case (Chains.HECO):
            return ("Huobi Eco Chain")
        case (Chains.MATIC):
            return ("Polygon")
        case (Chains.XDAI):
            return ("Gnosis")
        case (Chains.ELASTOS):
            return ("Elastos")
        case (Chains.HOO):
            return ("Hoo")
        case (Chains.KCC):
            return ("Kucoin")
        case (Chains.MOONRIVER):
            return ("Moonriver")
        case (Chains.OKEX):
            return ("OKEx")
        case (Chains.HARMONY):
            return ("Harmony")
        case (Chains.TELOS):
            return ("Telos")
        case (Chains.CRONOS):
            return ("Cronos")
        case (Chains.FUSE):
            return ("Fuse")
        case (Chains.IOTEX):
            return ("IoTeX")
        case (Chains.ETHEREUM):
            return ("Ethereum")
        case (Chains.ARBITRUM):
            return ("Arbitrum")
        case (Chains.OPTIMISM):
            return ("Optimism")
        case (Chains.KAVA):
            return ("Kava")
        case (Chains.BITTORRENT):
            return ("BitTorrent")
        default:
            return ("Avalanche")
    }
}